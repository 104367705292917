export const sponsors = [
  // {
  //   name: "Assam Tourism",
  //   image: "assam-tourism.png",
  // },
  {
    name: "SBI",
    image: "SBI LOGO.png",
  },
  {
    name: "Arunachal Tourism",
    image: "Arunachal Bamboo Logo.png",
  },
  // {
  //   name: "Manipur Tourism",
  //   image: "manipur-tourism.png",
  // },
  {
    name: "Manipuris in Kolkata",
    image: "Manipuris in Kolkata.png",
  },
  // {
  //   name: "Nagaland Tourism",
  //   image: "nagaland-tourism.png",
  // },
  {
    name: "Mising",
    image: "Mising Logo.png",
  },
  {
    name: "Deep Think AI",
    image: "Deep Think AI.png",
  },
  // {
  //   name: "Tata Hitachi",
  //   image: "tata-hitachi.png",
  // },
  {
    name: "M_S DULANG TEA ESTATE",
    image: "M_S DULANG TEA ESTATE.png",
  },
  {
    name: "PWC",
    image: "Poddar Car World.jpeg",
  },
    // {
  //   name: "Oil India Limited",
  //   image: "oil.png",
  // },
  // {
  //   name: "ONGC",
  //   image: "ongc.png",
  // },
]
import styles from './Alumni.module.css'

const Circle = () => {
    return ( 
        <div className={styles.circlebatch}>
            <h2>Batch</h2>
            <h2>2023</h2>
        </div>
     );
}
 
export default Circle;
import styles from './Alumni.module.css';
import Circle from './CircleBatch';

const AlumniCard = () => {
    return ( 
        <div className={styles.cardbatch}>
            <Circle/>
        </div>
     );
}
 
export default AlumniCard;